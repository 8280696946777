import { useState } from "react";
import { DateRange } from '../DateRange';
import { Dropdown } from '../Dropdown';
import { Delete24Regular } from "@fluentui/react-icons";
import { Text } from "@fluentui/react";
import { FiltersData } from "../../api";

import styles from './Filters.module.css';

interface Props {
    onClose: any;
    setFilters: any;
    filters: FiltersData;
    isDisabled: boolean;
    subjects: string[];
    collections: string[];
    authors: string[];
}

export const Filters = ({ onClose, setFilters, filters, isDisabled, subjects, collections, authors } : Props) => {

  const [internalFilter, setInternalFilter] = useState(filters);

  const handleClearFilters = () => {
    const emptyFilters: FiltersData = {
      author: [],
      collection: [],
      subject: [],
      date_from: null,
      date_to: null
    };
    setInternalFilter(emptyFilters);
  };

  const close = (save: boolean) => {
    if (save){
      setFilters(internalFilter);
    }
    onClose();
  }

  const disabledStyle = {
    filter: 'grayscale(100%)',
    opacity: 0.5,
    cursor: 'not-allowed'
  };

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersHeader}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          <b style={{ alignSelf: 'flex-start' }}>Filtros</b>
          <button className={styles.closeButton} onClick={() => close(false)}>Cerrar</button>
        </div>
      </div>
      <DateRange 
        initialStartDate={internalFilter.date_from}
        initialEndDate={internalFilter.date_to} 
        onDateChange={(date_from, date_to) => setInternalFilter({ ...internalFilter, date_from, date_to })}
        disabled={isDisabled}/>
      <Dropdown 
        label="Orden jurisdiccional" 
        options={subjects}
        initialOptions={internalFilter.subject}
        onChange={(selectedSubjects) => setInternalFilter({ ...internalFilter, subject: selectedSubjects ?? [] })}
        disabled={isDisabled}/> 
      <Dropdown 
        label="Tipo de documento" 
        options={collections}
        initialOptions={internalFilter.collection}
        onChange={(selectedCollections) => setInternalFilter({ ...internalFilter, collection: selectedCollections ?? [] })}
        disabled={isDisabled}/>
      <Dropdown
        label="Autores" 
        options={authors}
        initialOptions={internalFilter.author}
        onChange={(selectedAuthors) => setInternalFilter({ ...internalFilter, author: selectedAuthors ?? [] })}
        disabled={isDisabled}/> 
      <div className={styles.cleanFiltersButtonContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          <button className={styles.applyButton} onClick={() => close(true)}>Confirmar</button>
          <div>
            <Delete24Regular className={styles.cleanFiltersButton} style={isDisabled ? disabledStyle : {}} onClick={isDisabled ? () => {} : handleClearFilters} />
            <Text className={styles.cleanFiltersButton}>Limpiar filtros</Text>
          </div>
        </div>
      </div>
    </div>
  );
}