import { Component } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #a52925;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    &:hover {
        filter: brightness(90%);
    }
`;

interface Props {
    children: any;
    onClick: any;
}

export const ShowFiltersButton = ({ children, onClick } : Props) => {
    return (
        <ButtonWrapper onClick={onClick}>
            {children}
        </ButtonWrapper>
    );
};
